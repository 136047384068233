<template>
  <div>
    <AppStatusCart />
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "QuotaRouterView",
  components: {
    AppStatusCart: () => import("~cp/AppStatusCart/AppStatusCart"),
  },
  beforeRouteEnter(to, from, next) {
    if (to.name === "QuotaSum" && to.query.fromAdmin === "true") {
      next((vm) => {
        if (!vm.$store.getters["authModule/isSuperUser"]) {
          vm.$store.commit("authModule/LOGOUT");
          vm.$Tawk.$endChat();
          return false;
        }
        if (to.params.quotaId) {
          vm.$router.replace({ query: {} });
          vm.$store.dispatch("quota/getQuotaById", to.params.quotaId);
        }
      });
    } else {
      next(async (vm) => {
        if (to.params.quotaId) {
          await vm.$store.dispatch("quota/getQuotaById", to.params.quotaId);
          if (
            vm.$store.getters["authModule/isSuperUser"] &&
            vm.$store.getters["quota/isQuotaSigned"]
          ) {
            if (vm.$route.name !== "QuotaSum") {
              vm.$router.replace({
                name: "QuotaSum",
                params: {
                  quotaId: vm.$store.getters["quota/getCurrentQuotaId"],
                },
              });
            }
          } else {
            next();
          }
        }
      });
    }
  },
};
</script>

<style></style>
